import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
  Theme
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { FC, useState } from "react";
import { IUserModel } from "../../models/IUserModel";
import UserRegistration, { IUserForm } from "../UserRegistration";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    title: {
      textAlign: "center",
      boxSizing: "border-box"
    },
    red: {
      color: theme.palette.error.main
    },
    green: {
      color: theme.palette.success.main
    },
    actions: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end"
    },
    content: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(2)
    }
  })
);

export interface IEditUser {
  user?: IUserModel;
  open: boolean;
  close: () => void;
}

const EditUser: FC<IEditUser> = ({ user: passedUser, close, open }) => {
  const classes = useStyles();

  const [user] = useState<IUserForm>({
    id: passedUser?.id || "",
    firstName: passedUser?.firstName || "",
    lastName: passedUser?.lastName || "",
    password: "",
    email: passedUser?.email || "",
    phone: passedUser?.phone || "",
    roleId: passedUser?.roleId ? String(passedUser.roleId) : "",
    customerId:
      String(passedUser?.roleId) === "3" && passedUser?.Offices?.length
        ? passedUser?.Offices[0].customerId
        : "",
    officeIds: passedUser?.Offices
      ? passedUser?.Offices.map((office) => office.id)
      : []
  });

  return (
    <Dialog
      transitionDuration={100}
      className={classes.root}
      open={open}
      onClose={close}
    >
      <DialogTitle className={classes.title}>
        {user.id ? "Benutzer*in bearbeiten" : "Benutzer*in registrieren"}
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <UserRegistration user={user} />
      </DialogContent>
    </Dialog>
  );
};

export default observer(EditUser);
