import {
  Box,
  Button,
  createStyles,
  FormControl,
  makeStyles,
  Modal,
  TextField,
  Theme,
  Typography
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { getNotiOptions, TNotiVariant } from "../../constants/configs";
import { IProjectModel } from "../../models/IProjectModel";
import ParticipantService from "../../services/ParticipantService";
import UserStore from "../../stores/UserStore";
import LoadingAnimation from "../LoadingAnimation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      margin: theme.spacing(1),
      gap: theme.spacing(4)
    },
    modal: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      position: "absolute",
      top: "25%",
      left: "75%",
      transform: "translate(-50%, -50%)",
      width: "fit-content",
      backgroundColor: "white",
      // border: "2px solid #000",
      padding: theme.spacing(2),
      borderRadius: "5px"
    },
    btn: {
      color: theme.palette.primary.light
    },
    btnBox: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between"
    }
  })
);

export interface IExportProject {
  participantId?: string;
  open: boolean;
  close: () => void;
}

const ExportProject: FC<IExportProject> = ({ participantId, open, close }) => {
  const classes = useStyles();
  const { user } = useContext(UserStore);
  const { enqueueSnackbar } = useSnackbar();

  const [userProjects, setUserProjects] = useState<Array<IProjectModel>>(
    [] as IProjectModel[]
  );
  const [project, setProject] = useState<IProjectModel>({} as IProjectModel);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSelect = (project: IProjectModel | null) => {
    if (project) {
      setProject(project);
    }
  };

  const handleExport = async () => {
    setLoading(true);
    if (!project.id) return;
    try {
      const rsp = await ParticipantService.exportParticipants(
        project.id,
        participantId ? [participantId] : null,
        project.longName
      );
      let title: string = "Es sind keine Ergebnisse verfügbar";
      let type: TNotiVariant = "warning";
      if (rsp) {
        if (rsp === 4000) {
          title = "Keine Bewerbungen zum exportieren vorhanden";
        } else {
          title = "Export erfolgreich";
          type = "success";
        }
      }
      enqueueSnackbar(title, getNotiOptions(type));
    } catch (e) {
      enqueueSnackbar("Ein Fehler ist aufgetreten", getNotiOptions("error"));
    }
    setLoading(false);
  };

  useEffect(() => {
    const projects: IProjectModel[] = [];
    user?.Offices?.forEach((office) =>
      office?.Projects?.forEach((project) => {
        if (!projects.map((proj) => proj.id).includes(project.id)) {
          projects.push(project);
        }
      })
    );
    setUserProjects(projects);
    if (projects.length === 1) {
      setProject(projects[0]);
    }
  }, [user.Offices]);

  return (
    <Modal open={open} onClose={close} onBackdropClick={close}>
      <Box className={classes.modal}>
        <Typography variant="h6">Bitte Wählen Sie ein Projekt</Typography>
        <FormControl className={classes.formControl}>
          <Autocomplete
            getOptionLabel={(option) => option?.longName || ""}
            id="project-select"
            noOptionsText="Keine Projekte verfügbar"
            onChange={(e, proj) => handleSelect(proj)}
            options={userProjects}
            value={project}
            renderOption={(option) => (
              <Typography> {option.longName}</Typography>
            )}
            renderInput={(params) => <TextField {...params} label="Projekt" />}
          />
          {loading ? (
            <LoadingAnimation />
          ) : (
            <Box className={classes.btnBox}>
              <Button
                onClick={close}
                variant="contained"
                color="secondary"
                className={classes.btn}
              >
                Abbrechen
              </Button>
              <Button
                disabled={!project || loading}
                onClick={handleExport}
                variant="contained"
                color="secondary"
                className={classes.btn}
              >
                Download
              </Button>
            </Box>
          )}
        </FormControl>
      </Box>
    </Modal>
  );
};

export default observer(ExportProject);
