import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Theme,
  Tooltip
} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { parseAndFormat } from "../functions/date";
import { IResultModel } from "../models/IResultModel";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {},
    mainTable: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      boxSizing: "border-box"
    },
    table: {},
    tableRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between"
    },
    tableCell: {
      border: "none"
    },
    tableHeader: {
      color: "white",
      height: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    totalContainer: {
      display: "flex",
      paddingTop: "1rem",
      boxSizing: "border-box"
    },
    total: {
      fontWeight: 600,
      fontSize: "1rem",
      borderTop: "1px solid lightgrey"
    },
    totalResult: {
      textAlign: "end"
    },
    btnGroup: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxSizing: "border-box",
      padding: theme.spacing(1)
    },
    downloadContainer: {
      width: "200px",
      height: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    btn: {
      margin: theme.spacing(1)
    },
    input: {
      padding: "none",
      paddingLeft: theme.spacing(1)
    },
    diaBtnBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around"
    },
    white: {
      color: theme.palette.primary.light
    },
    red: {
      color: theme.palette.error.main
    },
    date: {
      textAlign: "center",
      paddingBottom: theme.spacing(2)
    }
  });
});

export interface IAcResults {
  acResults?: IResultModel[];
  acFinalResult?: IResultModel;
}

export function formatPercent(n: number) {
  const rounded = Math.round((n * 100 + Number.EPSILON) * 100) / 100;
  return `${rounded.toFixed(2)}%`;
}

const AcResults: FC<IAcResults> = ({ acResults, acFinalResult }) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.date}>
        Teilnahmedatum:{" "}
        {acFinalResult?.Group?.Event?.date
          ? parseAndFormat(acFinalResult?.Group.Event.date)
          : "Kein Datum verfügbar"}
      </Typography>
      <TableContainer>
        <Table className={classes.table} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>
                <Typography>Kompetenzen</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {acResults?.map((result, i) => {
              const value = formatPercent(result.value);
              return (
                <TableRow key={i} className={classes.tableRow}>
                  <Tooltip title={value}>
                    <TableCell className={classes.tableCell}>
                      {result.typeObject?.longName}
                    </TableCell>
                  </Tooltip>
                  <TableCell className={classes.tableCell}>{value}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer className={classes.totalContainer}>
        <Table
          className={classes.table}
          size="small"
          aria-label="simple table"
          style={{ width: "100%" }}
        >
          <TableBody>
            <TableRow key={acFinalResult?.id}>
              <TableCell className={`${classes.tableCell} ${classes.total}`}>
                Gesamtergebnis:
              </TableCell>
              <TableCell
                className={`${classes.tableCell} ${classes.total} ${classes.totalResult}`}
              >
                {formatPercent(acFinalResult?.value ?? 0)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default observer(AcResults);
